





















































































import useAlert from "@/use/alert";
import useCompanyMode from "@/use/companyMode";
import useUser from "@/use/user";
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    ALogo: () => import("@/components/atoms/a-logo.vue"),
  },

  props: ["value"],

  setup(_, { root }) {
    const { hasAccessTo } = useUser({ root });
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;

    const {
      alertData,
      getAlertType,
      getAlertTypeIcon,
      getAlertTimeLeft,
      getAlertMileageLeft,
      getAlertStatus,
      getAlertStatusColor,

      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileage,
      getAlertEndMileageColor,

      getAlertVehicle,
      getAlertCompany,
    } = useAlert();

    const {
      isCompanyModeEnabled,
      getCompanyId,
      getCompanyData,
    } = useCompanyMode({ root });

    const state = reactive({
      loading: false,
      unread: 0,
      alerts: [] as any[],
      fetchInterval: null as any,
    });

    const fetchAlerts = () => {
      if (hasAccessTo.value("customer")) {
        state.loading = true;

        const filter = isCompanyModeEnabled.value
          ? {
              company: getCompanyId.value || undefined,
            }
          : undefined;

        axiosInstance
          .get("alert", {
            params: { page: 1, itemsPerPage: 5, status: ["to_do"], filter },
          })
          .then(({ data }) => {
            state.alerts = data.alerts;
            state.unread = data.total;
          })
          .catch(() => {
            state.alerts = [];
            state.unread = 0;
          })
          .finally(() => (state.loading = false));
      }
    };

    onMounted(fetchAlerts);
    watch(() => root.$route.fullPath, fetchAlerts);
    watch(getCompanyData, fetchAlerts, { deep: true });
    onMounted(() => (state.fetchInterval = setInterval(fetchAlerts, 30000)));
    onBeforeUnmount(() => clearInterval(state.fetchInterval));

    const logout = () => {
      root.$store.dispatch("user/logout").then(() => {
        root.$router.push({ name: "auth" });
      });
    };

    return {
      hasAccessTo,

      alertData,
      getAlertType,
      getAlertTypeIcon,
      getAlertTimeLeft,
      getAlertMileageLeft,
      getAlertStatus,
      getAlertStatusColor,
      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileage,
      getAlertEndMileageColor,
      getAlertVehicle,
      getAlertCompany,

      state,

      fetchAlerts,
      logout,
    };
  },
});
